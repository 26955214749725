import React, {useState} from "react";
import ReactDOM from "react-dom/client";

function FavoriteColor()
{
  const [color,setColor]= useState("red");

  return (
    <>
      <h1>My favorite color is  {color}</h1>
      <button 
        type="button"
        onClick={() => setColor("blue")}
        >Blue </button>

      <button  
        type="button"
        onClick={() => setColor("yellow")}
        >Yellow </button>
      <button 
        type="button"
        onClick={() => setColor("red")}
        >Red </button>

<button 
        type="button"
        onClick={() => setColor("green")}
        >Green</button>
    </>
  )
}

const root=ReactDOM.createRoot(document.getElementById('root'));
root.render(<FavoriteColor/>)